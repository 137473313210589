import { cn } from '@hapstack/common'
import type {
  ButtonStyleProps,
  IconButtonStyleProps,
  IconName,
} from '@hapstack/ui'
import { buttonStyles, Icon, iconButtonStyles, Spinner } from '@hapstack/ui'
import type { LinkProps } from '@remix-run/react'
import { Link, useNavigation } from '@remix-run/react'
import type { ComponentProps } from 'react'

interface LinkButtonProps
  extends ButtonStyleProps,
    Omit<ComponentProps<typeof Link>, 'color'> {
  disabled?: boolean
  loading?: boolean
  icon?: IconName
}

export const LinkButton = ({
  size,
  variant,
  disabled,
  className,
  icon,
  loading,
  ...props
}: LinkButtonProps) => {
  const navigation = useNavigation()

  const isLoading =
    loading !== undefined
      ? loading
      : navigation.state !== 'idle' &&
        navigation.location?.pathname === props.to

  return (
    <Link
      {...props}
      className={cn(
        'flex items-center justify-center gap-2',
        buttonStyles({
          variant,
          size,
        }),
        disabled && 'pointer-events-none opacity-50',
        className
      )}
    >
      {isLoading ? <Spinner /> : icon ? <Icon name={icon} /> : null}
      {props.children}
    </Link>
  )
}

interface LinkIconButtonProps
  extends IconButtonStyleProps,
    Omit<LinkProps, 'color'> {
  disabled?: boolean
  icon: IconName
  label: string
}

export const LinkIconButton = ({
  to,
  size,
  variant,
  disabled,
  className,
  icon,
  label,
  ...props
}: LinkIconButtonProps) => {
  const navigation = useNavigation()

  const isLoading =
    navigation.state !== 'idle' && navigation.location?.pathname === to

  return (
    <Link
      to={to}
      {...props}
      className={cn(
        disabled && 'pointer-events-none opacity-50',
        iconButtonStyles({
          size,
          variant,
        }),
        className
      )}
    >
      <Icon
        name={isLoading ? 'loader-2' : icon}
        className={cn('h-full w-full shrink-0', isLoading && 'animate-spin')}
      />
    </Link>
  )
}
